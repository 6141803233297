import React from 'react'
import styled from 'styled-components'
import { Button } from "./Button"

const Rollout = () => {
  return (
    <RolloutSection>
      <RolloutTitle>Ready for Success?</RolloutTitle>
      <RolloutText>Start by filling out my project assesment form - it takes literally just few minutes to get the ball rolling!</RolloutText>
      <Button to="/projectassesment" primary="false" fweight="true">Request Proposal</Button>
      
    </RolloutSection>
  )
}

export default Rollout

const RolloutSection = styled.section`
    background-color: #2A2C2B;
    display:flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
    padding-bottom: 2em;
`

const RolloutTitle = styled.h2`
    color: #ffffff;
    font-size: 2em;
    text-transform: uppercase;
    margin: 1em 0 .6em 0;

    @media screen and (max-width: 768px){
      font-size: 1.6em;
    }
`

const RolloutText = styled.p`
    text-align: center;
    color: #ffffff;
    width: 40%;
    margin-bottom: 1em;

    @media screen and (max-width: 768px){
      width: 60%;
    }
`