import GATSBY_COMPILED_MDX from "/codebuild/output/src897646365/src/jarihillukkala/content/blog/what-makes-a-good-construction-company-website.mdx";
import {graphql} from 'gatsby';
import React from 'react';
import Layout from "../components/Layout";
import Rollout from "../components/Rollout";
import AppSeo from "../components/AppSeo";
import styled from 'styled-components';
import {Button} from "../components/Button";
function BlogPost({data: {mdx}, children}) {
  return React.createElement(Layout, null, React.createElement(AppSeo, {
    title: mdx.frontmatter.title
  }), React.createElement(BlogPostSection, null, React.createElement(BlogPostTitle, null, mdx.frontmatter.title), React.createElement(BlogPostDate, null, mdx.frontmatter.date), children, React.createElement(BlogButton, {
    to: "/blog"
  }, "<", " Back to blog ")), React.createElement(Rollout));
}
export const query = graphql`
query ($id: String!){
    mdx(id: {eq: $id}){
        frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
        }
        id
    }
}
`;
BlogPost
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
const BlogPostSection = (styled.section)`
    margin: 3rem 9.375rem 5rem;
    padding: 0 calc((100vw - 1440px) / 1.5);
    color: #2A2C2B;
    list-style-position: inside;

    @media screen and (max-width: 1020px){
        margin: 2rem 2rem;
    }
  
    @media screen and (max-width: 768px){
        margin: 2rem 1rem;
    }
`;
const BlogPostDate = (styled.p)`
    font-size: .8em;
    color: #374140;
    margin-bottom: 2rem;
`;
const BlogPostTitle = (styled.h1)`
    font-weight: 600;
    font-size: 1.8em;
    color: #374140;
    margin-bottom: .2rem;
`;
const BlogButton = (styled(Button))`  
    margin-top: 4rem;
    display: block;
`;
